import './ConveyerBelt.css';
import React from "react";
import conveyerBeltFrame from '../../assets/images/conveyerBeltFrame.png'
import conveyerBeltLines from '../../assets/images/conveyerBeltLines.png'
import lycheetini from '../../assets/images/lycheetini.png'
import noodleBowl from '../../assets/images/noodleBowl.png'
import sojuSunrise from '../../assets/images/sojuSunrise.png'
import friedEgg from '../../assets/images/friedEgg.png'

function ConveyerBelt() {

    return (
        <div className="conveyer">
        <div className="conveyerBelt">
        <img className="conveyerBeltFrame" src={conveyerBeltFrame} alt="conveyer belt frame with 8 short legs"/>
        <img className="conveyerBeltFrameLines frameLines1" src={conveyerBeltLines} alt="conveyer belt lines"/>
        <img className="conveyerBeltFrameLines frameLines2" src={conveyerBeltLines} alt="conveyer belt lines"/>
        <img className="lycheetini" src={lycheetini} alt="lycheetini drawing - a curved glass with a light green lychee drink inside"/>
        <img className="lycheetini2" src={lycheetini} alt="lycheetini drawing - a curved glass with a light green lychee drink inside"/>
        <img className="noodleBowl noodle1" src={noodleBowl} alt="drawing of a red bowl with noodles inside, some green scallions on top"/>
        <img className="noodleBowl noodle2" src={noodleBowl} alt="drawing of a red bowl with noodles inside, some green scallions on top"/>
        <img className="sojuSunrise soju1" src={sojuSunrise} alt="drawing of our soju sunrise cocktail, a champagne glass with orange filling"/>
        <img className="sojuSunrise soju2" src={sojuSunrise} alt="drawing of our soju sunrise cocktail, a champagne glass with orange filling"/>
        <img className="friedEgg egg1" src={friedEgg} alt="drawing of a fried egg inside a red checkered paper tray"/>
        <img className="friedEgg egg2" src={friedEgg} alt="drawing of a fried egg inside a red checkered paper tray"/>
    </div>
    </div>
        )
}

export default ConveyerBelt;