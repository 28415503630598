import './App.css';
import React, { Component } from "react";
import welcome from "./assets/welcome.svg"
// import events_star from "./assets/events_star.svg"
// import catering_star from "./assets/catering_star.svg"
import brunch_star from "./assets/brunch_star.svg"
import Footer from "./pages/Footer.js"
// import Header from "./pages/Header.js"
import Carousel from "./pages/Carousel.js"
import Slideshow from "./pages/Slideshow.js"
import EmailForm from "./pages/email/EmailForm.js"

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    const appClass = isMobile ? 'AppMobile' : 'App';
    const logoClass = isMobile ? 'Logo' : 'LogoFull';
    // const brunchStarClass = isMobile ? ''
    let orderDiv = {}
    if (!isMobile) {
      orderDiv = <div className="OrderRow">
      <a href={"/brunch"} rel="noreferrer" class="order-href">
            <img className="brunchStarDesktop" src={brunch_star} alt="now with bottomless brunch"></img>
          </a>
        <div className="OrderButton">
          <a href={"/LIC"} rel="noreferrer" class="order-href">
            <p class="order"> Long Island City </p>
          </a>
        </div>
        <div className="OrderButton">
          <a href={"/EV"} rel="noreferrer" class="order-href">
            <p class="order"> East Village </p>
          </a>
        </div>
      </div>
    } else {
      orderDiv = <div> 
      <div className="OrderButton">
      <a href={"/LIC"} rel="noreferrer" class="order-href">
        <p class="order"> Long Island City </p>
      </a>
    </div>
    <div className="OrderButton">
    <a href={"/brunch"} rel="noreferrer" class="order-href">
          <img className="brunchStar" src={brunch_star} alt="now with bottomless brunch"></img>
        </a>
      <a href={"/EV"} rel="noreferrer" class="order-href">
        <p class="order"> East Village </p>
      </a>
      </div>
      </div>
    }

    return (
      <div className={appClass}>
        <img className={logoClass} src={welcome} alt="welcome to instant noodle factory"></img>
        <Slideshow/>
        {orderDiv}
          <Carousel isMobile = {isMobile}></Carousel>
          <EmailForm isMobile = {isMobile}></EmailForm>
          <Footer></Footer>
        </div>
      );
    }
  }


export default App;
