import './FormSuccess.css';
import React from "react";


function FormSuccessGeneric(props) {
    return (
        <div className={props.isMobile ? "successWindowMobile" : "successWindow"}>
            <button className="xButton" onClick={() => props.setShowSuccessMessage(false)}> x </button>
            <p class="discountText"> thanks for subscribing! </p>
            <p className="classCode"> your code will be emailed to you soon</p>
        </div>
    )
}

export default FormSuccessGeneric;