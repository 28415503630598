import './Brunch.css';
import React, {useState} from "react";
import expandTriangle from '../../assets/images/expandTriangle.png'
import ConveyerBelt from './ConveyerBelt.js'

function Brunch() {
    const [showBrunchMenu, setShowBrunchMenu] = useState(false)
    const [showSlurpMenu, setShowSlurpMenu] = useState(false)

    return (
        <div class="body">
            <div class="headerRow">
                <p className="brunchHeader streetwear"> bottomless @</p>
                <p className="brunchHeader windsor"> INF</p>
            </div>
                <ConveyerBelt/>
                <a className="reservationlink" href="https://www.opentable.com/r/instant-noodle-factory-downtown-reservations-new-york?restref=1376461&lang=en-US&ot_source=Restaurant%20website" target="_blank" rel="noreferrer">
                    <p class="reservation"> check for reservations</p>
                </a>
                <p className="menuSubtext"> or send us a text to book at 856-823-9113</p>


                <p className="brunchHeader windsorSmall"> bottomless brunch</p>
                <div className="subHeaderDropDown">
                    <p className="menuSubtext"> thursday-sunday opening - 5pm</p>
                    <img className={showBrunchMenu ? "expandTriangle" : "expandTriangleFlipped"} 
                        src={expandTriangle} alt="symbol to expand the show menu selection" 
                        onClick={() => setShowBrunchMenu(!showBrunchMenu)}/>
                </div>
                {showBrunchMenu && 
                <div className="menuDropdown">
                    <h1 class="brunchHeader windsorSmall"> MENU </h1>
                    <div className="menuOption">
                        <h1 class="menu"> Option A: $35</h1>
                        <p class="menuDescriptionHeader"> bottomless noodles and bottomless brunch cocktails </p>
                        <p class="menuDescription">-all you can eat noodles</p>
                        <p class="menuDescription">-classic toppings platter</p>
                        <p class="menuDescription"> -bottomless brunch cocktails featuring our lycheetini, tokyo mule, and soju sunrise</p>
                        <p class="menuDescription"> --optional $2 fried egg add ons</p>
                    </div>
                    <div className="menuOption">
                        <h1 class="menu"> Option B: $15 </h1>
                        <p class="menuDescriptionHeader"> bottomless noodles </p>
                        <p class="menuDescription">-all you can eat noodles</p>
                        <p class="menuDescription">-classic toppings platter</p>
                        <p class="menuDescription"> --optional $2 fried egg add ons</p>
                    </div>
                    <div className="menuOption">
                        <h1 class="menu"> Option C: $30</h1>
                        <p class="menuDescriptionHeader"> bottomless brunch cocktails with entree </p>
                        <p class="menuDescription"> -bottomless brunch cocktails featuring our lycheetini, tokyo mule, and soju sunrise</p>
                        <p class="menuDescription"> -choice of one of our 8 signature entrees</p>
                    </div>
                </div>
                }

                <p className="brunchHeader windsorSmall"> endless sip and slurp</p>
                <div className="subHeaderDropDown">
                    <p className="menuSubtext"> wednesday-saturday 5pm - closing</p>
                    <img className={showSlurpMenu ? "expandTriangle" : "expandTriangleFlipped"} 
                        src={expandTriangle} alt="symbol to expand the show menu selection" 
                        onClick={() => setShowSlurpMenu(!showSlurpMenu)}/>
                </div>
                {showSlurpMenu && 
                <div className="menuDropdown">
                    <h1 class="brunchHeader windsorSmall"> MENU </h1>
                    <div className="menuOption">
                        <h1 class="menu"> Option A: $30</h1>
                        <p class="menuDescriptionHeader"> bottomless cocktails with entree </p>
                        <p class="menuDescription"> -bottomless cocktails featuring our lycheetini, tokyo mule, and soju sunrise</p>
                        <p class="menuDescription"> -choice of one of our 8 signature entrees</p>
                    </div>
                    <div className="menuOption">
                        <h1 class="menu"> Option B: $35</h1>
                        <p class="menuDescriptionHeader"> bottomless cocktails with a custom bowl </p>
                        <p class="menuDescription">-bottomless cocktails featuring our lycheetini, tokyo mule, and soju colada</p>
                        <p class="menuDescription">-a custom bowl of your choosing, with one protein, two premium toppings, and three classic toppings</p>
                    </div>
                </div>
                }
            </div>
        )
}

export default Brunch;