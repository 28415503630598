import './FormSuccess.css';
import React from "react";
import twentyoff from '../../assets/twentyoff.png'


function FormSuccess(props) {
    return (
        <div className={props.isMobile ? "successWindowMobile" : "successWindow"}>
            <button className="xButton" onClick={() => props.setShowSuccessMessage(false)}> x </button>
            <p class="discountText"> Thanks for Subscribing! </p>
            <img className="twentyOff" src={twentyoff} alt="take twenty percent off your first order"></img>
            <p className="classCode"> your code: email20zhy</p>
            <p class={props.isMobile ? "formSuccessText formSuccessTextMobile" : "formSuccessText"}> to use at LIC: must enter order via online site - kiosk does not accept promo codes! follow QR code in store to build your bowls, and then at checkout, enter your promo code. Your promo code is unique and can only be used once!</p>
            <p class={props.isMobile ? "formSuccessText formSuccessTextMobile" : "formSuccessText"}> to use at EV: show a screenshot of this page at checkout! not valid during bottomless brunch</p>
        </div>
    )
}

export default FormSuccess;