import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Order from './pages/Order';
import Preset from './pages/Preset';
import Custom from './pages/Custom';
import Shop from './pages/Shop';
import Events from './pages/subpages/Events';
import Catering from './pages/subpages/Catering';
import LICLinks from './pages/links/LICLinks';
import EVLinks from './pages/links/EVLinks';
import LIC from './pages/locations/LIC';
import EV from './pages/locations/EV';
import FormSuccess from './pages/email/FormSuccess';
import Brunch from './pages/subpages/Brunch';
import SipAndSlurp from './pages/photoPages/SipAndSlurp'
import EVfoodmenu from './pages/photoPages/EVfoodmenu';
import EVdrinksmenu from './pages/photoPages/EVdrinksmenu';
import EventsCalendar from './pages/subpages/EventsCalendar';
import EmailSignUp from './pages/email/EmailSignUp';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
       <Routes>
        <Route path="/" element={ <App/> } />
        <Route path="/order" element={ <Order/> } />
        <Route path="/preset" element={ <Preset/> } />
        <Route path="/custom" element={ <Custom/> } />
        <Route path="/shop" element={ <Shop/> } />
        <Route path="/liclinks" element={ <LICLinks/> } />
        <Route path="/evlinks" element={ <EVLinks/> } />
        <Route path="/lic" element={ <LIC/> } />
        <Route path="/ev" element={ <EV/> } />
        <Route path="/events" element={ <Events/> } />
        <Route path="/catering" element={ <Catering/> } />
        <Route path="/brunch" element={ <Brunch/> } />
        <Route path="/sipandslurp" element={ <SipAndSlurp/> } />
        <Route path="/evdrinks" element={ <EVdrinksmenu/> } />
        <Route path="/evfood" element={ <EVfoodmenu/> } />
        <Route path="/formsuccess" element={ <FormSuccess/> } />
        <Route path="/eventscalendar" element={<EventsCalendar/> } />
        <Route path="/emailsignup/:location" action={({ params }) => {}} element={ <EmailSignUp />}/>
      </Routes>
      </BrowserRouter>,
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
