import './EmailSignUp.css';
import React, {useState} from "react";
import { NavLink, useParams } from "react-router-dom";
import { generateClient } from "@aws-amplify/api";
import { createCustomer } from '../../graphql/mutations';
import FormSuccessGeneric from './FormSuccessGeneric.js'

const client = generateClient()



function EmailSignUp(props) {
    let params = useParams();


  const [customerEmail, setCustomerEmail] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const handleInputChange = (event) => {
    setCustomerEmail(event.target.value);
  };

  const addCustomer = async (noodle) => {
    try {
      const newCustomer = { ...{
        "email": customerEmail,
        "locationPreference": params.location
      } };
      await client.graphql({
        query: createCustomer,
        variables: {
          input: newCustomer
        }
      });
      setCustomerEmail("")
      setShowErrorMessage(false)
      setShowSuccessMessage(true)
    } catch (err) {
      setShowErrorMessage(true)
      console.log('error uploading customer:', err);
  }}

    return (
        <div>
          <p class="largeThanksText"> thanks for coming! </p>
          <p class="percentText"> get 10% off your next order</p>
          <p class="emailText"> and receive exclusive weekly coupons by joining our noodle notification list!</p>
          <input
              className="signUpTypeBox"
              type="text"
              id="customerEmail"
              value={customerEmail}
              onChange={handleInputChange}
              name="customer"
              placeholder="enter email here"
            />
          <button className="submitBox" onClick={() => addCustomer()}> submit </button>
          {showErrorMessage && 
          <div>
            <p className="errorMessage"> error - email not valid</p>
          </div>}
          <p class="subText"> coupon will be sent within 24-72 hours of form submission! please reach out to inoodlefactory@gmail.com if  you don't receive your coupon after 72 hours</p>
          {showSuccessMessage && 
          <FormSuccessGeneric isMobile = {props.isMobile} setShowSuccessMessage={setShowSuccessMessage}/>}
          <NavLink className="linkButton" type="submit" to="/">
                <p className="linkButtonText"> back to home </p>
            </NavLink>
        </div>
    )
}

export default EmailSignUp;